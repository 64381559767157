import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import ThemedSuspense from './components/ThemedSuspense';
import { Analytics } from '@vercel/analytics/react';
import './index.css';


const HomePage = lazy(() => import('./pages/Home'));
const Dashboard = lazy(() => import('./pages/Dashboard'));
const Documents = lazy(() => import('./pages/Documents'));
const Summary = lazy(() => import('./pages/SummaryDocument'))
const Generate = lazy(() => import('./pages/GenerateDocument'))
const Page404 = lazy(() => import('./pages/404'));
const SignUp = lazy(() => import('./pages/CreateAccount'))
const SignIn = lazy(() => import('./pages/Login'));


function App() {
  return (
    <Router>
      <Suspense fallback={<ThemedSuspense />}>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/documents" element={<Documents />} />
          <Route path="/summary" element={<Summary />} />
          <Route path="/generate" element={<Generate/>} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/404" element={<Page404 />} />
          <Route path="*" element={<Navigate to="/404" />} />
        </Routes>
        <Analytics />
      </Suspense>
    </Router>
  );
}

export default App;