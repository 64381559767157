import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client'; // Обновлено для использования новой API
import App from './App';
import './styles/tailwind.output.css';
import ThemedSuspense from './components/ThemedSuspense';
import { Windmill } from '@windmill/react-ui';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(

    <Suspense fallback={<ThemedSuspense />}>
      <Windmill usePreferences>
        <App />
      </Windmill>
    </Suspense>
);
